import React from "react"

import Layout from "../components/Layout"

export default function Four() {
  const myStyle = {
    textAlign: "center",
  }
  return (
    <Layout title="Page Not Found" description="404 Error - Page Not Found">
      <div className="abouts segments-page default-container">
        <div className="container">
          <h2 style={myStyle}>Uh Oh...Looks like that page doesn't exist!</h2>
        </div>
      </div>
    </Layout>
  )
}
